/*
 * Cookie Consent Manager
 * ======================
 * TrustArc cookie consent API integration.
 *
 */
window.CookieConsentMgr = (function() {

   var cookieConsentDomain = "coldwellbanker.com";
   var functionalConsentQueue = [];
   var adConsentQueue = [];
   var functionalTagsApproved = false;
   var adTagsApproved = false;

   function getCookieConsent(type, cb) {
      //console.log('getCookieConsent', type);
      if (window.truste && window.truste.cma) {
         var response = truste.cma.callApi("getConsent", cookieConsentDomain, null, null, type);
         if(window.console) {
            console.info('Cookie consent updated for ' + type + " - " + response.consent);
         }
         if (response.consent == "approved") {
            cb(true);
         } else {
            cb(false);
         }
      } else {
         setTimeout(function () {
            getCookieConsent(type, cb);
         }, 100);
      }
   }

   var cookieConsentHandler = function(e) {
      //console.log('cookieConsentHandler called....', e);
      try {
         var responseJson = JSON.parse(e.data);
         //console.log(responseJson);
         if (responseJson.hasOwnProperty('PrivacyManagerAPI') ||
              responseJson.source === 'preference_manager'
         ) {
            //console.log('INSIDE HANDLER', adTagsApproved, adConsentQueue.length);

            getCookieConsent("advertising", function (isApproved) {
               adTagsApproved = isApproved;
               if (adTagsApproved && adConsentQueue.length > 0) {
                  for (var i = 0; i < adConsentQueue.length; i++) {
                     //console.log('processing ad queue');
                     adConsentQueue[i]();
                  }
                  adConsentQueue = [];
               }
               if(!adTagsApproved) {
                  // Hide the share link (addthis tag)
                  $('.glyphicon-share').hide();
               }
            });

            getCookieConsent("functional", function (isApproved) {
               functionalTagsApproved = isApproved;
               if (functionalTagsApproved && functionalConsentQueue.length > 0) {
                  for (i = 0; i < functionalConsentQueue.length; i++) {
                     //console.log('processing functional queue');
                     functionalConsentQueue[i]();
                  }
                  functionalConsentQueue = [];
               }
            });
         }
      } catch (error) {
         // Per TrustArc - this is their recommended way of handling,
         // sometimes data is JSON encoded in a string, sometimes it's
         // other stuff too so we just try to parse the JSON and swallow the errors. :(
         //console.log('Cookie consent error: ', error);
      }
   }

   this.initializeCookieConsent = function() {
      //console.log("Initalize cookie consent");
      var apiObject = {
         PrivacyManagerAPI: {
            action: "getConsent",
            timestamp: new Date().getTime(),
            self: cookieConsentDomain
         }
      };
      var apiResponseJson = JSON.stringify(apiObject);
      window.top.postMessage(apiResponseJson, "*");
      window.addEventListener("message", cookieConsentHandler, false);
   }

   this.includeFunctionalTag = function(callback, forceToTop) {
      //console.log('Include Functional Tag');
      if (functionalTagsApproved) {
         callback();
      } else {
         if (forceToTop) {
            functionalConsentQueue.unshift(callback);
         } else {
            functionalConsentQueue.push(callback);
         }
      }
   }

   this.includeAdTag = function(callback, forceToTop) {
     // console.log('Include Ad Tag');
     // console.trace();
     // console.log('Queueing Ad Tag', adTagsApproved);
      if (adTagsApproved) {
         callback();
      } else {
         if(forceToTop) {
            adConsentQueue.unshift(callback);
         } else {
            adConsentQueue.push(callback);
         }
      }
   }

   return this;
}());

CookieConsentMgr.initializeCookieConsent();